<template>
  <div class="co-container-lg mb-28 md:mb-28">
    <Swiper
      :modules="[SwiperPagination]"
      :slides-per-view="1"
      :space-between="32"
      :breakpoints="{
        '768': {
          slidesPerView: 2,
        },
        '992': {
          slidesPerView: 3,
        },
      }"
      :pagination="{
        clickable: true,
      }"
    >
      <SwiperSlide
        :key="1"
        class="!h-auto"
      >
        <div
          class="h-full bg-co-gray-50 p-4 shadow-[0_0_4px_rgba(51,51,51,0.2)] md:py-6"
          style="margin-top: 1px; margin-bottom: 2px;"
        >
          <div class="mb-3 flex items-start">
            <img
              class="te-img-reviews-twitter mr-3 rounded-full"
              src="~/assets/images/Te/TeTop/ReviewsTwitter/farmer_40.jpg"
              loading="lazy"
              alt=""
              width="48"
              height="48"
            >
            <div>
              <p class="mb-0 font-bold">
                miki@農家→WEB制作を目指す人
              </p>
              <p class="co-display-14 mb-0 text-co-gray-500">
                @farmer_40
              </p>
            </div>
          </div>
          <p class="mb-0">
            <span class="co-marker-yellow-1 font-bold">コーディング案件を受注しました！</span>
            <br>
            さらっと見る限りできそうな予感。もちろん完成＆納品します。メンターさんと今後の勉強方法について相談。めちゃくちゃ心強い！
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide
        :key="2"
        class="!h-auto"
      >
        <div
          class="h-full bg-co-gray-50 p-4 shadow-[0_0_4px_rgba(51,51,51,0.2)] md:py-6"
          style="margin-top: 1px; margin-bottom: 2px;"
        >
          <div class="mb-3 flex items-start">
            <img
              class="te-img-reviews-twitter mr-3 rounded-full"
              src="~/assets/images/Te/TeTop/ReviewsTwitter/Tsukasa_ninja08.jpg"
              loading="lazy"
              alt="いつまる-designe"
              width="48"
              height="48"
            >
            <div>
              <p class="mb-0 font-bold">
                いつまる-designe
              </p>
              <p class="co-display-14 mb-0 text-co-gray-500">
                @Tsukasa_ninja08
              </p>
            </div>
          </div>
          <p class="mb-0">
            テックアカデミー卒業後はご縁から営業させていただいた<span class="co-marker-yellow-1 font-bold">企業様からホームページ、会社案内等の受注を承りありがたい限りです。</span>
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide
        :key="3"
        class="!h-auto"
      >
        <div
          class="h-full bg-co-gray-50 p-4 shadow-[0_0_4px_rgba(51,51,51,0.2)] md:py-6"
          style="margin-top: 1px; margin-bottom: 2px;"
        >
          <div class="mb-3 flex items-start">
            <img
              class="te-img-reviews-twitter mr-3 rounded-full"
              src="~/assets/images/Te/TeTop/ReviewsTwitter/EHcl24kKpzBw861.jpg"
              loading="lazy"
              alt="ゆーか｜Web制作勉強中"
              width="48"
              height="48"
            >
            <div>
              <p class="mb-0 font-bold">
                ゆーか｜Web制作勉強中
              </p>
              <p class="co-display-14 mb-0 text-co-gray-500">
                @EHcl24kKpzBw861
              </p>
            </div>
          </div>
          <p class="mb-0">
            なんと本日、<span class="co-marker-yellow-1 font-bold">Web制作会社より内定を頂きました！！</span>勉強の甲斐がありました！テックアカデミーさんやTwitterの皆さんに感謝です。
            <br>
            これからも勉強頑張ります！
          </p>
        </div>
      </SwiperSlide>
      <SwiperSlide
        :key="4"
        class="!h-auto"
      >
        <div
          class="h-full bg-co-gray-50 p-4 shadow-[0_0_4px_rgba(51,51,51,0.2)] md:py-6"
          style="margin-top: 1px; margin-bottom: 2px;"
        >
          <div class="mb-3 flex items-start">
            <img
              class="te-img-reviews-twitter mr-3 rounded-full"
              src="~/assets/images/Te/TeTop/ReviewsTwitter/piku_book.jpg"
              loading="lazy"
              alt="ピク@IT勉強中"
              width="48"
              height="48"
            >
            <div>
              <p class="mb-0 font-bold">
                ピク@IT勉強中
              </p>
              <p class="co-display-14 mb-0 text-co-gray-500">
                @piku_book
              </p>
            </div>
          </div>
          <p class="mb-0">
            転職活動を終了しました！
            <br>
            ・内定2社
            <br>
            <span class="co-marker-yellow-1 font-bold">受託開発や自社開発をしている会社に就職しました。</span>
            <br>
            就活は最初の方は大変でしたが<span class="co-marker-yellow-1 font-bold">無事に内定をいただけてよかったです！</span>
            <br>
            #エンジニア転職
          </p>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<style scoped>
@import url('~/assets/css/Te/TeCommon/TeCommonSwiper.css');

.te-img-reviews-twitter {
  width: 2.5rem;
  @media (min-width: 992px) {
    width: 3rem;
  }
}

.co-marker-yellow-1 {
  background: linear-gradient(transparent 70%,#ff3 0);
}
</style>

<template>
  <div class="co-container-lg mx-auto px-4">
    <div class="bg-white px-4 pb-10 md:px-10 md:pb-12 md:pt-8">
      <div class="mb-8 flex flex-wrap items-center justify-center">
        <div class="mb-10 basis-full md:mb-0 md:basis-5/12 md:pr-8 lg:basis-4/12">
          <a
            class="te-recommend-image block"
            :href="$host() + '/course/first-sidejob'"
          >
            <h3
              class="te-recommend-image-text-small px-5 pt-24 font-bold text-co-primary-400"
              style="text-shadow: 0 0 0.25rem #fff, 0 0 0.4rem #fff"
            ><span class="te-recommend-image-text-large">副業</span>に<br>おすすめ</h3></a>
        </div>
        <div class="basis-full md:basis-7/12 lg:basis-8/12">
          <h4 class="te-recommend-title mb-5 text-center font-bold md:text-left">
            はじめての副業コース
          </h4>
          <ul class="mb-0 list-none">
            <li class="mb-2">
              <i
                class="fa fa-check mr-2"
                aria-hidden="true"
                :style="{ color: '#1F7F96' }"
              /><span class="font-bold"><span class="co-marker-yellow-1">Web制作の副業に必要なスキル</span>が学べる</span>
            </li>
            <li class="mb-2">
              <i
                class="fa fa-check mr-2"
                aria-hidden="true"
                :style="{ color: '#1F7F96' }"
              /><span class="font-bold">入門的なカリキュラムで<span class="co-marker-yellow-1">初心者でも安心</span></span>
            </li>
            <li>
              <i
                class="fa fa-check mr-2"
                aria-hidden="true"
                :style="{ color: '#1F7F96' }"
              /><span class="te-marker-text font-bold">納品まで徹底サポート！<span class="co-marker-yellow-1">最短で副業にトライ</span></span>
            </li>
          </ul>
        </div>
      </div>
      <h5 class="co-display-10 mb-8 text-center font-bold text-co-primary-500">
        副業サポートの特長
      </h5>
      <div class="mb-4 flex flex-wrap justify-center">
        <TeCommonBoxCircleGreen class="te-box-circle mb-8 md:mr-3 lg:mb-0 lg:mr-6">
          <template #number>
            <span
              class="co-display-9 font-bold text-co-gray-50"
              :style="{ lineHeight: 0 }"
            >1</span>
          </template>
          <template #text>
            <div class="px-6 pb-5 pt-8">
              <p
                class="co-display-10 mb-3 text-center font-bold"
                :style="{ lineHeight: 1.5 }"
              >
                仕事をご紹介
              </p>
              <p class="m-0">
                独自の仕事をご紹介！<br><span class="font-bold text-co-primary-500">はじめての仕事を100%獲得</span>できます。
              </p>
            </div>
          </template>
        </TeCommonBoxCircleGreen>
        <TeCommonBoxCircleGreen class="te-box-circle mb-8 md:ml-3 lg:mb-0 lg:ml-0 lg:mr-6">
          <template #number>
            <span
              class="co-display-9 font-bold text-co-gray-50"
              :style="{ lineHeight: 0 }"
            >2</span>
          </template>
          <template #text>
            <div class="px-6 pb-5 pt-8">
              <p
                class="co-display-10 mb-3 text-center font-bold"
                :style="{ lineHeight: 1.5 }"
              >
                作業〜納品までサポート
              </p>
              <p class="m-0">
                <span class="block sm:inline">仕事の進行管理・不明点の回答など</span><span class="font-bold text-co-primary-500">プロが納品までサポート</span>します。
              </p>
            </div>
          </template>
        </TeCommonBoxCircleGreen>
        <TeCommonBoxCircleGreen class="te-box-circle">
          <template #number>
            <span
              class="co-display-9 font-bold text-co-gray-50"
              :style="{ lineHeight: 0 }"
            >3</span>
          </template>
          <template #text>
            <div class="px-6 pb-5 pt-8">
              <p
                class="co-display-10 mb-3 text-center font-bold"
                :style="{ lineHeight: 1.5 }"
              >
                継続して稼げる
              </p>
              <p class="m-0">
                <span class="block sm:inline">報酬と労力が適正な仕事だから、</span><span class="font-bold text-co-primary-500">安定した収入</span>を目指せます。
              </p>
            </div>
          </template>
        </TeCommonBoxCircleGreen>
      </div>
      <div class="co-display-14 mb-8 text-co-gray-500">
        ※実力判定テスト合格後1ヶ月以内に案件を紹介<br>※紹介された案件をお受けにならない場合は1ヶ月の保証対象外
      </div>
      <!-- 1000案件バナー -->
      <div class="te-bg-banner-thousand mb-8 rounded">
        <div class="co-container-sm flex flex-col items-center justify-center md:flex-row md:px-4">
          <div class="items-center px-0 md:flex md:basis-6/12 lg:basis-7/12">
            <div class="my-5 mr-4 hidden md:block">
              <img
                src="~/assets/images/Te/TeTop/BootcampRecommend/people.svg"
                alt="1000案件イラスト"
              >
            </div>
            <div class="te-thousand-title co-display-7 mb-1 py-4 text-center font-bold text-co-gray-50 md:text-left">
              <span class="te-marker-primary-1">多数の案件</span><span class="co-display-11 font-bold">から</span><br><span class="te-marker-primary-1 text-co-yellow-500">あなたにあった</span><span class="te-marker-primary-1">仕事</span><span class="co-display-11 font-bold">を紹介</span>
            </div>
          </div>
          <div class="my-0 px-0 text-co-gray-50 md:my-4 md:basis-6/12 lg:basis-5/12">
            <div class="te-thousand-text mb-3 mt-1 flex justify-center">
              <div class="mr-6 flex flex-col items-center lg:mr-5 lg:flex-row">
                <div class="te-thousand-icon mb-1 flex items-center justify-center rounded-full bg-co-warning-500 lg:mb-0 lg:mr-2">
                  <img
                    src="~/assets/images/Te/TeTop/BootcampRecommend/beginner.svg"
                    alt="初心者向け"
                  >
                </div>
                <div class="text-center lg:text-left">
                  初心者向けの<br>トライアル案件
                </div>
              </div>
              <div class="flex flex-col items-center lg:flex-row">
                <div class="te-thousand-icon mb-1 flex items-center justify-center rounded-full bg-co-warning-500 lg:mb-0 lg:mr-2">
                  <img
                    src="~/assets/images/Te/TeTop/BootcampRecommend/large-company.svg"
                    alt="大手企業"
                  >
                </div>
                <div class="text-center lg:text-left">
                  数百万円規模の<br>大手企業の案件
                </div>
              </div>
            </div>
            <div class="text-center">
              <div class="co-display-11 te-thousand-subtitle inline-block px-6 pb-2 pt-1 font-bold leading-none text-co-gray-50">
                年間<span class="co-display-7 font-bold text-co-yellow-500">1,000</span>案件以上の納品実績
              </div>
              <p class="co-display-14 mb-2 mt-1 text-center text-co-gray-50 md:mb-0">
                ※2021年10月〜2022年9月の案件納品数
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <a
          class="co-btn-primary"
          :href="$host() + '/course/first-sidejob'"
        >はじめての副業コースへ<i
          class="fa fa-angle-right te-btn-icon"
          aria-hidden="true"
        /></a>
      </div>
    </div>
    <!-- Webデザインコース -->
    <div class="mt-5 rounded bg-co-gray-50 px-4 pb-10 md:px-10 md:pb-12 md:pt-8">
      <div class="mb-8 flex flex-wrap items-center justify-center">
        <div class="mb-10 basis-full md:mb-0 md:basis-5/12 md:pr-8 lg:basis-4/12">
          <a
            class="te-recommend-image te-recommend-image-2 block"
            :href="$host() + '/course/webdesign'"
          >
            <h3 class="te-recommend-image-text te-recommend-image-text-small pl-3 font-bold text-co-primary-400 lg:pl-7">Webデザインコース</h3>
          </a>
        </div>
        <div class="basis-full md:basis-7/12 lg:basis-8/12">
          <h4 class="te-recommend-title mb-5 text-center font-bold md:text-left">
            案件保証付き<br>Webデザインコース
          </h4>
          <ul class="mb-0 list-none">
            <li class="mb-2">
              <i
                class="fa fa-check mr-2"
                aria-hidden="true"
                style="color: #1f7f96"
              /><span class="font-bold"><span class="co-marker-yellow-1">バナー・サイトなど</span>デザイナーに必要なスキルが全て学べる</span>
            </li>
            <li class="mb-2">
              <i
                class="fa fa-check mr-2"
                aria-hidden="true"
                style="color: #1f7f96"
              /><span class="font-bold">バナーの案件保証。オリジナルサイトも作れて<span class="co-marker-yellow-1">副業・転職への確実な第一歩に</span></span>
            </li>
            <li>
              <i
                class="fa fa-check mr-2"
                aria-hidden="true"
                style="color: #1f7f96"
              /><span class="te-marker-text font-bold">最新のツールも学べる。<span class="co-marker-yellow-1">Adobeツールが3ヶ月無料</span></span>
            </li>
          </ul>
        </div>
      </div>
      <div
        class="text-center"
        style="margin: 0 0 30px"
      >
        <a
          class="co-btn-primary"
          :href="$host() + '/course/webdesign'"
        >Webデザインコースへ<i
          class="fa fa-angle-right te-btn-icon"
          aria-hidden="true"
        /></a>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TeCommonBoxCircleGreen from '~/components/Te/TeCommon/Box/TeCommonBoxCircleGreen/TeCommonBoxCircleGreen.vue'

const options = {
  components: {
    TeCommonBoxCircleGreen,
  },
}

export default defineNuxtComponent(options)
</script>

<style scoped>
/* 副業コース背景画像 */
.te-recommend-image {
  width: calc(100% + 2rem);
  height: 11.875rem;
  margin-left: -1rem;
  background-image: url('~/assets/images/Te/TeTop/BootcampRecommend/bg-sidejob.jpg');
  background-position: right;
  background-size: cover;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  @media (min-width: 768px) {
    width: 100%;
    margin-left: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

/* Webデザインコース背景画像 */
.te-recommend-image-2 {
  background-image: url('~/assets/images/Te/TeTop/BootcampRecommend/bg-sidejob-2.webp');
}

.te-recommend-image-text {
  padding-top: 9.5rem;
  text-shadow: 0 0 0.25rem #fff, 0 0 0.4rem #fff;
}

.te-recommend-image-text-small {
  font-size: 1.5rem;
}

/* 副業におすすめのテキスト */
.te-recommend-image-text-large {
  font-size: 2.375rem;
}

.te-recommend-title {
  font-size: 1.25rem;
  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}

.co-marker-yellow-1 {
  background: linear-gradient(transparent 70%, #ff3 0);
}

.te-box-circle {
  width: 100%;
  @media (min-width: 768px) {
    width: calc((100% - 24px) / 2);
  }
  @media (min-width: 992px) {
    width: calc((100% - 48px) / 3);
  }
}

/* 1000案件バナー */
.te-bg-banner-thousand {
  background-color: #61a7b7;
}

.te-thousand-title {
  line-height: 1.25;
}

.te-marker-primary-1 {
  background: linear-gradient(transparent 70%, #1f7f96 0);
}

.te-thousand-text {
  line-height: 1.5;
}

.te-thousand-icon {
  min-width: 3rem;
  max-width: 3rem;
  min-height: 3rem;
  max-height: 3rem;
}

.te-thousand-subtitle {
  position: relative;
  background-color: #1f7f96;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 0;
    content: '';
    border-bottom: solid 2.5rem transparent;
    border-left: solid 1rem #61a7b7;
  }
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    content: '';
    border-bottom: solid 2.5rem #61a7b7;
    border-left: solid 1rem transparent;
  }
}

.te-btn-icon {
  font-size: 1.125rem;
}
</style>

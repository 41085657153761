<template>
  <div class="px-4">
    <a
      class="mx-auto block max-w-[400px] hover:opacity-80 md:hidden"
      :href="$host() + '/course/occupation'"
    >
      <img
        src="~/assets/images/Te/TeCommon/Banner/Occupation/occupation-banner-sp.svg"
        class="co-aspectratio-attr mx-auto w-full"
        alt="コース選びに役立つ解説！これを読めばWeb制作の仕事がまるわかり"
        width="400"
      >
    </a>
    <a
      class="mx-auto hidden max-w-[740px] hover:opacity-80 md:block"
      :href="$host() + '/course/occupation'"
    >
      <img
        src="~/assets/images/Te/TeCommon/Banner/Occupation/occupation-banner-pc.svg"
        class="co-aspectratio-attr mx-auto"
        alt="コース選びに役立つ解説！これを読めばWeb制作の仕事がまるわかり"
        width="740"
      >
    </a>
  </div>
</template>

<style scoped>
.co-aspectratio-attr {
  aspect-ratio: attr(width) / attr(height);
}
</style>

<template>
  <div class="flex flex-col items-center px-4">
    <TeCommonBalloon
      v-if="showNotification == 'today'"
      class="te-emphasize-balloon relative mb-2 inline-block border-co-danger-500 bg-co-danger-500 !px-9 !py-2 text-white"
    >
      <div class="flex items-center">
        <img
          src="~/assets/images/Te/TeTop/Deadline/icon-flame.svg"
          class="mr-1"
          style="min-width: 20px; max-width: 20px; min-height: 20px; max-height: 20px"
        >
        <span>本日締切</span>
      </div>
    </tecommonballoon>
    <TeCommonBalloon
      v-if="showNotification == 'aLittleLeft'"
      class="te-emphasize-balloon relative mb-2 inline-block border-co-danger-500 bg-co-danger-500 !px-9 !py-2 text-white"
    >
      <div class="flex items-center">
        <img
          src="~/assets/images/Te/TeTop/Deadline/icon-flame.svg"
          class="mr-1"
          style="min-width: 20px; max-width: 20px; min-height: 20px; max-height: 20px"
        >
        <span>残りわずか</span>
      </div>
    </TeCommonBalloon>
    <div class="te-emphasize relative mb-8 w-full py-4 text-center">
      <div class="te-emphasize-text font-bold leading-none">
        <span class="te-emphasize-text-01 absolute m-auto">締</span>
        <span class="te-emphasize-text-02 absolute m-auto">切</span>
      </div>
      <div class="mb-3 leading-none text-white">
        <img
          src="~/assets/images/Te/TeTop/Deadline/icon-clock.svg"
          class="mr-2 inline align-middle"
          style="min-width: 20px; max-width: 20px; min-height: 20px; max-height: 20px"
        >
        <span class="co-display-10 align-middle leading-none">申込締切</span>
      </div>
      <div class="co-display-13 mx-auto inline-block bg-white px-5 text-co-danger-500 sm:px-[3.25rem]">
        <span class="co-display-7 px-1 font-bold">{{ month }}</span>月
        <span class="co-display-7 px-1 font-bold">{{ day }}</span>日
        <span class="px-1">（{{ dayOfWeek }}）</span>
        <span class="co-display-7 px-1 font-bold">{{ hours }}</span>時
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { formatDayOfWeek } from '~/utility/utility'
import type { Cohort } from 'types/constantType'
import TeCommonBalloon from '~/components/Te/TeCommon/Balloon/TeCommonBalloon/TeCommonBalloon.vue'

interface Data {
  leftTime: number | null
}

interface Computed {
  closesAt: Date | null
  yesterdayClosesAt: Date | null
  dayOfWeek: string | '-'
  month: number | '-'
  hours: number | '-'
  day: number | '-'
  showNotification: string | null
}

interface Props {
  cohort: Cohort
}

const options = {
  data(): Data {
    return {
      leftTime: null,
    }
  },
  filters: {
    formatTime(value: number) {
      if (value < 10) {
        return '0' + value
      }
      return value
    },
  },
  props: {
    cohort: {
      type: Object,
    } as PropOptions<Cohort>,
  },
  computed: {
    closesAt(): Date | null {
      return this.cohort ? new Date(this.cohort.attributes.closes_at) : null
    },
    yesterdayClosesAt(): Date | null {
      if (this.closesAt) {
        const yesterday = new Date(this.closesAt)
        yesterday.setDate(yesterday.getDate() - 1)
        return yesterday
      } else {
        return null
      }
    },
    hours(): number | '-' {
      return this.closesAt ? this.closesAt.getHours() + 24 : '-'
    },
    day(): number | '-' {
      return this.yesterdayClosesAt ? this.yesterdayClosesAt.getDate() : '-'
    },
    month(): number | '-' {
      return this.yesterdayClosesAt
        ? this.yesterdayClosesAt.getMonth() + 1
        : '-'
    },
    dayOfWeek(): string | '-' {
      return this.yesterdayClosesAt
        ? formatDayOfWeek(this.yesterdayClosesAt)
        : '-'
    },
    showNotification(): string | null {
      if (this.closesAt) {
        const now = new Date()
        const leftDays =
          (this.closesAt.getTime() - now.getTime()) / (1000 * 60 * 60 * 24)
        if (leftDays > 0 && leftDays < 1) {
          return 'today'
        } else if (leftDays > 1 && leftDays < 3) {
          return 'aLittleLeft'
        } else {
          return null
        }
      } else {
        return null
      }
    },
  },
  components: {
    TeCommonBalloon,
  },
}

export default defineNuxtComponent(options)
</script>

<style scoped>
.te-emphasize-balloon {
  z-index: 1;
}

.te-emphasize {
  max-width: 21.875rem;
  background: #ecb9b6;
  @media (min-width: 576px) {
    max-width: 30.625rem;
  }
}

.te-emphasize-text {
  font-size: 2.625rem;
  color: #ecc9c8;
  @media (min-width: 576px) {
    font-size: 4.5rem;
  }
  .te-emphasize-text-01 {
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
  }
  .te-emphasize-text-02 {
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
}
</style>

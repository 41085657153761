<template>
  <section
    id="recommend"
    class="co-bg-striped py-12 sm:py-20"
  >
    <div class="co-container-xs">
      <h2 class="co-heading-2-caption">
        おすすめコース
      </h2>
      <h2 class="co-heading-2-caption hidden">
        副業・転職を目指せるコース
      </h2>
      <p class="mb-12 px-4 text-center text-co-gray-600">
        現役エンジニアが受講後の活躍まで見据えて
        <br class="md:hidden">
        マンツーマンサポート
      </p>
    </div>
    <TeTopBootcampDeadline
      class="mb-2"
      :cohort="cohort"
    />

    <TeTopRecommendSideJob />
  </section>
</template>

<script lang="ts">
import { getLatestCohort, getFirstOpenedWholeCohort } from '~/utility/fetchAPI'
import TeTopBootcampDeadline from '~/components/Te/TeTop/TeTopBootcampDeadline.vue'
import TeTopRecommendSideJob from '~/components/Te/TeTop/TeTopRecommendSideJob.vue'

const options = {
  data() {
    return {
      cohort: null,
      firstOpenedWholeCohort: null,
    }
  },
  mounted() {
    getLatestCohort(this.$api).then((response) => {
      this.cohort = response.data
    })
    getFirstOpenedWholeCohort(this.$api).then((response) => {
      this.firstOpenedWholeCohort = response.data
    })
  },
  components: {
    TeTopBootcampDeadline,
    TeTopRecommendSideJob,
  },
}

export default defineNuxtComponent(options)
</script>

<style scoped>
.te-recommend {
  background-color: #f7f9f9;
}

.te-top-course-catch {
  font-size: 1rem;
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
}

.te-btn-icon {
  font-size: 1.125rem;
}
</style>

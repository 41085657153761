<template>
  <section class="co-bg-striped relative pb-9 pt-5">
    <h2 class="co-display-12 mb-3 w-full text-center font-bold leading-7 tracking-widest text-co-gray-600">
      ＼ 知る・体験する ／
    </h2>
    <div class="co-container-xs flex flex-wrap px-4">
      <a
        :href="$host() + '/trial/htmlcss-trial'"
        class="mb-4 w-full rounded text-co-gray-600 md:w-1/2 md:pr-2"
      >
        <TeCommonBoxShadowBlue class="flex items-center justify-between pb-4 pl-4 pr-3 pt-3">
          <template #content>
            <div class="flex">
              <img
                class="pr-4"
                src="~/assets/images/Te/TeCommon/Banner/Links/icon-1.svg"
                alt="無料で体験する"
              >
              <div>
                <h3 class="co-display-11 mb-0 font-bold leading-7">
                  <span>無料で</span>
                  <span class="text-co-primary-400">体験する</span>
                </h3>
                <span class="leading-normal">プログラミングを気軽に体験できる</span>
              </div>
            </div>
            <i
              class="fa fa-chevron-right text-co-gray-500"
              aria-hidden="true"
            />
          </template>
        </TeCommonBoxShadowBlue>
      </a>
      <a
        :href="$host() + '/counseling/htmlcss-schedule'"
        class="bot_open mb-4 w-full rounded text-co-gray-600 md:w-1/2 md:pl-2"
      >
        <TeCommonBoxShadowBlue class="flex items-center justify-between pb-4 pl-4 pr-3 pt-3">
          <template #content>
            <div class="flex">
              <img
                class="pr-4"
                src="~/assets/images/Te/TeCommon/Banner/Links/icon-2.svg"
                alt="説明動画を見る"
              >
              <div>
                <h3 class="co-display-11 mb-0 font-bold leading-7">
                  <span>説明動画を</span>
                  <span class="text-co-primary-400">見る</span>
                </h3>
                <span class="leading-normal">各コースの魅力を無料の動画で説明</span>
              </div>
            </div>
            <i
              class="fa fa-chevron-right text-co-gray-500"
              aria-hidden="true"
            />
          </template>
        </TeCommonBoxShadowBlue>
      </a>
    </div>
    <div class="mt-7">
      <div class="co-container-xs te-counseling-banner-content flex flex-col items-center justify-center px-4 md:flex-row md:items-start">
        <img
          class="te-counseling-illust"
          src="~/assets/images/Te/TeCommon/Banner/Links/counseling-illust.svg"
          alt="無料相談"
        >
        <p class="mx-0 mb-0 md:ml-6 md:mr-5">
          <span>受講についてカウンセラーが丁寧にご案内いたします。</span>
          <span class="font-bold text-co-danger-500">とりあえず話だけを聞きたい</span>
          <span>という方も多くご利用いただいております。ご質問・ご相談事もOK！</span>
        </p>
        <a
          :href="$host() + '/trial/htmlcss-trial'"
          class="flex items-center justify-center gap-2 md:flex-col"
        >
          <div class="te-counseling-icon-item mx-auto flex items-center justify-center rounded-full bg-white">
            <i class="fa fa-angle-right" />
          </div>
          <div
            class="text-center leading-normal"
            style="font-size: 0.875rem"
          >
            <span class="inline whitespace-nowrap font-bold md:block">相談を</span>
            <span class="inline whitespace-nowrap font-bold md:block">予約する</span>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import TeCommonBoxShadowBlue from '~/components/Te/TeCommon/Box/TeCommonBoxShadowBlue/TeCommonBoxShadowBlue.vue'

const options = {
  components: {
    TeCommonBoxShadowBlue,
  },
}

export default defineNuxtComponent(options)
</script>

<style scoped>
@import url("~/assets/css/Te/TeCommon/Banner/TeCommonBannerLinks.css");
</style>

<template>
  <div>
    <div class="co-container-lg px-4 pt-5 text-left md:text-center">
      <div class="flex flex-wrap justify-center">
        <a
          class="te-course-block relative border  bg-white hover:opacity-80"
          :href="$host() + '/course/first-sidejob'"
        >
          <img
            class="te-course-popular absolute"
            src="~/assets/images/Te/TeWorks/Course/works_no1.svg"
            alt="人気No.1"
          >
          <div class="te-course-title te-course-item-first-sidejob flex flex-col justify-center text-center font-bold text-white">
            はじめての<br>副業コース
          </div>
          <div class="te-course-caption md:co-p-3 p-2 text-left text-co-gray-700 lg:p-4">
            Web制作に必要なスキルを学び最短で副業にトライ
          </div>
        </a>
        <a
          class="te-course-block border  bg-white hover:opacity-80"
          :href="$host() + '/course/webdesign'"
        >
          <div class="te-course-title te-bg-webdesign-trans-70 flex flex-col justify-center text-center font-bold text-white">
            Webデザイン<br>コース
          </div>
          <div class="te-course-caption md:co-p-3 p-2 text-left text-co-gray-700 lg:p-4">
            Webデザインのスキルを学びオリジナルサイト制作
          </div>
        </a>
        <a
          class="te-course-block relative border  bg-white hover:opacity-80"
          :href="$host() + '/course/first-prompt-engineering'"
        >
          <div class="te-course-title te-bg-prompt-engineering-trans-80 flex flex-col justify-center text-center font-bold text-white">
            プロンプト<br>エンジニアリングコース
          </div>
          <div class="te-course-caption md:co-p-3 p-2 text-left text-co-gray-700 lg:p-4">
            Chat GPTなどのAIを使いこなし業務の生産性を向上
          </div>
        </a>
        <a
          class="te-course-block border  bg-white hover:opacity-80"
          :href="$host() + '/course/python'"
        >
          <div class="te-course-title te-bg-python-trans-70 flex flex-col justify-center text-center font-bold text-white">
            Python<br>コース
          </div>
          <div class="te-course-caption md:co-p-3 p-2 text-left text-co-gray-700 lg:p-4">
            AIエンジニアの入り口、機械学習・AIの基礎を習得
          </div>
        </a>
        <a
          class="te-course-block border  bg-white hover:opacity-80"
          :href="$host() + '/pro/jobchange-bootcamp'"
        >
          <div class="te-course-title te-bg-jobchange-trans-70 flex flex-col justify-center text-center font-bold text-white">
            エンジニア<br>転職保証コース
          </div>
          <div class="te-course-caption md:co-p-3 p-2 text-left text-co-gray-700 lg:p-4">
            Javaを学びエンジニアとしてIT企業へ転職
          </div>
        </a>
        <a
          class="te-course-block border  bg-white hover:opacity-80"
          :href="$host() + '/course/java'"
        >
          <div class="te-course-title te-bg-java-trans-70 flex flex-col justify-center text-center font-bold text-white">
            Java<br>コース
          </div>
          <div class="te-course-caption md:co-p-3 p-2 text-left text-co-gray-700 lg:p-4">
            システム開発やWeb開発に使われるJavaで開発を実践
          </div>
        </a>
        <a
          class="te-course-block border  bg-white hover:opacity-80"
          :href="$host() + '/course/frontend'"
        >
          <div class="te-course-title te-bg-frontend-trans-70 flex flex-col justify-center text-center font-bold text-white">
            フロントエンド<br>コース
          </div>
          <div class="te-course-caption md:co-p-3 p-2 text-left text-co-gray-700 lg:p-4">
            Webサービス画面を人気の技術で作成
          </div>
        </a>
        <a
          class="te-course-block border  bg-white hover:opacity-80"
          :href="$host() + '/course/php'"
        >
          <div class="te-course-title te-bg-php-trans-70 flex flex-col justify-center text-center font-bold text-white">
            PHP/Laravel<br>コース
          </div>
          <div class="te-course-caption md:co-p-3 p-2 text-left text-co-gray-700 lg:p-4">
            あらゆるWebサービスに使われるPHPで開発を実践
          </div>
        </a>
      </div>
    </div>
    <div class="mt-[3.25rem] text-center">
      <h3 class="te-top-course-catch mb-6 px-4 text-left md:text-center">
        <span class="inline md:block">プログラミングをはじめ、アプリ開発、Webデザインなど</span>
        <span class="inline md:block">多様なコースをご用意しております</span>
      </h3>
      <a
        class="co-btn-primary"
        :href="$host() + '/course'"
      >コース一覧はこちら</a>
    </div>
    <div class="mt-9 text-center">
      <h3 class="te-top-course-catch mb-6 px-4 text-left md:text-center">
        <span class="inline md:block">副業で稼げる動画クリエイターを目指すなら</span>
        <span class="inline md:block">テックアカデミーの系列スクール ムークリがオススメ</span>
      </h3>
      <a
        class="co-btn-secondary"
        href="https://techacademy.jp/moocres"
        target="_blank"
      >
        ムークリのサイトを見る
        <i
          class="fa fa-external-link"
          aria-hidden="true"
        />
      </a>
      <div class="co-display-13 mt-3 px-4 text-co-gray-500">
        ※ムークリはテックアカデミーと同じ会社が提供するスクールです
      </div>
    </div>
  </div>
</template>

<style scoped>
@import url("~/assets/css/Te/TeTop/TeTopBootcamp.css");
</style>

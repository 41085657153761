<template>
  <section
    id="top-bootcamp"
    class="co-bg-striped"
  >
    <div class="co-container-lg py-12 sm:py-20">
      <h2 class="co-heading-2-caption leading-normal">
        人気コース
      </h2>
      <p class="mx-4 mb-12 text-center text-co-gray-600">
        オンライン完結だから自分のペースで学べて<br class="block md:hidden">最短4週間でスキル習得
      </p>
      <TeTopBootcampDeadline :cohort="cohort" />

      <TeTopBootcampPopularCourse />

      <TeCommonBannerOccupation class="mt-[3.25rem]" />
    </div>
  </section>
</template>

<script lang="ts">
import { getLatestCohort } from '~/utility/fetchAPI'
// import TeTopBootcampTitle from '~/' // TODO: 移行作業するときにコメントアウトを外してファイルパス記述してください
import TeTopBootcampDeadline from '~/components/Te/TeTop/TeTopBootcampDeadline.vue'
import TeTopBootcampPopularCourse from '~/components/Te/TeTop/TeTopBootcampPopularCourse.vue'
import TeCommonBannerOccupation from '~/components/Te/TeCommon/Banner/TeCommonBannerOccupation.vue'

const options = {
  components: {
    // TeTopBootcampTitle,
    TeTopBootcampDeadline,
    TeTopBootcampPopularCourse,
    TeCommonBannerOccupation,
  },
  data() {
    return {
      cohort: null,
    }
  },

  computed: {},

  mounted() {
    getLatestCohort(this.$api).then((response) => {
      this.cohort = response.data
    })
  },
}
export default defineNuxtComponent(options)
</script>

<style scoped>
/* TODO: CSSを使用しているvueファイルに移動する
TODO: 使っていないCSSは削除する */

/* // トップページカテゴリアイコン */

/* .te-top-category-icons {
  position: absolute;
  top: 6.5rem;
  right: 1.25rem;
  bottom: -1.25rem;
  font-size: 8.125rem;
} */

/* // コース紹介の見出し */

/* .te-top-course-catch {
  font-size: 1rem;
  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
} */

/* // コース紹介の各コース導線 */

/* .te-course-block {
  width: calc(50% - 8px);
  margin-bottom: 20px;
  border-radius: 6px;
  @media (min-width: 576px) {
    width: calc(33% - 16px);
  }
  @media (min-width: 992px) {
    width: calc(25% - 16px);
  }
  &:nth-child(odd) {
    margin-right: 8px;
    @media (min-width: 576px) {
      margin-right: 8px;
      margin-left: 8px;
    }
  }
  &:nth-child(even) {
    margin-left: 8px;
    @media (min-width: 576px) {
      margin-right: 8px;
      margin-left: 8px;
    }
  }
  .te-course-title {
    height: 92px;
    font-size: 0.75rem;
    border-radius: 6px 6px 0 0;
    @media (min-width: 576px) {
      font-size: 0.875rem;
    }
    @media (min-width: 992px) {
      height: 120px;
      font-size: 1.125rem;
    }
    .te-course-icon {
      font-size: 1.25rem;
      @media (min-width: 992px) {
        font-size: 1.5rem;
      }
    }
    .te-middle-level {
      position: absolute;
      top: 4px;
      right: 4px;
      font-size: 0.625rem;
      border-radius: 2px;
    }
    &.te-course-item-first-sidejob {
      background: linear-gradient(
          rgba(137, 169, 94, 0.7) 0%,
          rgba(137, 169, 94, 0.7) 100%
        ),
        url('~@/assets/images/Te/TeWorks/Course/bg-first-sidejob.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &.te-bg-webdesign-trans-70 {
      background: linear-gradient(
          rgba(205, 191, 73, 0.7) 0%,
          rgba(205, 191, 73, 0.7) 100%
        ),
        url('~@/assets/images/Te/TeCourse/Index/WebSite/bg-website.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &.te-bg-python-trans-70 {
      background: linear-gradient(
          rgba(62, 123, 175, 0.7) 0%,
          rgba(62, 123, 175, 0.7) 100%
        ),
        url('~@/assets/images/Te/TeCourse/Index/Ai/bg-ai.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &.te-bg-jobchange-trans-70 {
      background: linear-gradient(
          rgba(0, 176, 132, 0.7) 0%,
          rgba(0, 176, 132, 0.7) 100%
        ),
        url('~@/assets/images/Te/TeCourse/Index/CareerChange/bg-jobchange.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &.te-bg-php-trans-70 {
      background: linear-gradient(
          rgba(119, 123, 180, 0.7) 0%,
          rgba(119, 123, 180, 0.7) 100%
        ),
        url('~@/assets/images/Te/TeCourse/Index/WebService/bg-webservice.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &.te-bg-frontend-trans-70 {
      background: linear-gradient(
          rgba(140, 122, 203, 0.7) 0%,
          rgba(140, 122, 203, 0.7) 100%
        ),
        url('~@/assets/images/Te/TeCourse/Index/WebService/bg-webservice.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &.te-bg-java-trans-70 {
      background: linear-gradient(
          rgba(248, 152, 32, 0.7) 0%,
          rgba(248, 152, 32, 0.7) 100%
        ),
        url('~@/assets/images/Te/TeCourse/Index/WebService/bg-webservice.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    &.te-bg-prompt-engineering-trans-80 {
      background: linear-gradient(
          rgba(138, 170, 211, 0.8) 0%,
          rgba(62, 129, 213, 0.8) 100%
        ),
        url('~@/assets/images/Te/TeCourse/Index/PromptEngineering/bg-prompt-engineering.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }
  .te-course-caption {
    font-size: 0.75rem;
    border-radius: 0 0 6px 6px;
    @media (min-width: 768px) {
      font-size: 0.875rem;
    }
    @media (min-width: 992px) {
      font-size: 1rem;
    }
  }
  .te-course-popular {
    top: -0.5rem;
    left: -0.5rem;
    z-index: 1;
    width: 3.25rem;
    height: 3.25rem;
  }
} */
</style>

<template>
  <div>
    <!-- TODO: 書き換える -->
    <!-- <TeTopCoverConsultation
      :paid="paid"
      :applied-htmlcss-trial="appliedHtmlcssTrial"
    /> -->

    <!-- TODO: 書き換える -->
    <!-- <TeCommonBannerMypage v-show="!paid && appliedHtmlcssTrial" /> -->

    <TeCommonReskillingBanner />

    <TeCommonBannerLinksTitle />
    <TeCommonBannerSettlement
      v-if="BannerNameForShowing === 'settlement'"
      :entry-settlement-time-limit="entrySettlementTimeLimit"
      :entry-payment-url="entryPaymentUrl"
    />
    <TeCommonBannerTimelimit
      v-if="BannerNameForShowing === 'discount'"
      :entry-discount-time-limit="entryDiscountTimeLimit"
    />
    <TeTopRecommend />
    <TeTopBootcamp />

    <TeCommonBannerDiagnoses />

    <TeTopBannerLinks />

    <section
      id="mentoring-video"
      class="co-bg-striped py-12 sm:py-20"
    >
      <div class="co-container-lg px-4">
        <h2 class="co-display-8 mb-4 text-center font-bold tracking-normal">
          ー 動画でチェック ー
        </h2>
        <h2 class="co-heading-2-caption">
          <span class="text-co-primary-400">現役エンジニア</span>
          <span>が</span>
          <span class="inline-block md:inline">サポートするから挫折しない</span>
        </h2>
        <p class="mb-10 text-left md:text-center">
          経験豊富な現役エンジニアがメンターとなって学習から副業までサポートします。
          <br class="hidden md:block">
          マンツーマンメンタリングで受講生がメンターに相談している実際の様子をご覧ください。
        </p>
      </div>
      <div class="co-container-xs px-4">
        <iframe
          src="//www.youtube.com/embed/xAYCEZalLeI?rel=0&amp;showinfo=0"
          allowfullscreen=""
          frameborder="0"
          class="aspect-video w-full"
        />
      </div>
    </section>

    <TeTopWork />

    <TeCommonAboutTechAcademy />
    <TeCommonComparisonSchoolSidejob :benefits="true" />

    <!-- 受講生の声 -->
    <div
      id="voice"
      class="co-bg-striped py-12 sm:py-20"
    >
      <div class="co-container-lg px-4">
        <h2 class="co-heading-2">
          受講生の声
        </h2>
        <h3 class="mb-8 text-center text-[1.25rem] font-bold md:mb-[3.25rem] md:text-[1.75rem]">
          <FontAwesomeIcon
            :icon="faXTwitter"
            class="fa-fw mr-3 md:mr-4"
          />
          活躍中の受講生の口コミ
        </h3>

        <TeTopReviewsTwitter />

        <h3 class="mb-8 text-center text-[1.25rem] font-bold md:mb-[3.25rem] md:text-[1.75rem]">
          <i
            class="fa fa-microphone mr-3 md:mr-4"
            aria-hidden="true"
          />
          受講生インタビュー
        </h3>
        <TeTopInterview />
      </div>
    </div>

    <TeCommonBannerBriefingHtmlcssTrial />

    <!-- 下部固定ボタン -->
    <TeCommonButtonFixedBottom />
  </div>
</template>

<script lang="ts">
import { useUser } from '~/store/user'
import { IndexServices } from '~/services/indexService'
import { scriptTagsByConfig } from '~/utility/scriptTags'
import { metaTags } from '~/utility/constants/metaTags'

// import TeTopCoverConsultation from '~/' // TODO: billy-staticで実施しているテストが終わってから移行する
// import TeCommonBannerMypage from '~/' // // TODO: billy-staticで実施しているテストが終わってから移行する
import TeCommonBannerLinksTitle from '~/components/Te/TeCommon/Banner/TeCommonBannerLinksTitle/TeCommonBannerLinksTitle.vue'
import TeCommonReskillingBanner from '~/components/Te/TeCommon/TeCommonReskillingBanner.vue'
import TeCommonBannerSettlement from '~/components/Te/TeCommon/Banner/TeCommonBannerSettlement/TeCommonBannerSettlement.vue'
import TeCommonBannerTimelimit from '~/components/Te/TeCommon/Banner/TeCommonBannerTimelimit/TeCommonBannerTimelimit.vue'
import TeTopRecommend from '~/components/Te/TeTop/TeTopRecommend.vue'
import TeTopBootcamp from '~/components/Te/TeTop/TeTopBootcamp.vue'
import TeCommonBannerDiagnoses from '~/components/Te/TeCommon/Banner/TeCommonBannerDiagnoses/TeCommonBannerDiagnoses.vue'
import TeTopBannerLinks from '~/components/Te/TeTop/TeTopBannerLinks.vue'
import TeTopWork from '~/components/Te/TeTop/TeTopWork.vue'
import TeCommonAboutTechAcademy from '~/components/Te/TeCommon/TeCommonAboutTechAcademy/TeCommonAboutTechAcademy.vue'
import TeCommonComparisonSchoolSidejob from '~/components/Te/TeCommon/Comparison/TeCommonComparisonSchoolSidejob/TeCommonComparisonSchoolSidejob.vue'
import TeTopReviewsTwitter from '~/components/Te/TeTop/TeTopReviewsTwitter.vue'
import TeTopInterview from '~/components/Te/TeTop/TeTopInterview.vue'
import TeCommonBannerBriefingHtmlcssTrial from '~/components/Te/TeCommon/Banner/TeCommonBannerBriefingHtmlcssTrial/TeCommonBannerBriefingHtmlcssTrial.vue'
import TeCommonButtonFixedBottom from '~/components/Te/TeCommon/Button/TeCommonButtonFixedBottom/TeCommonButtonFixedBottom.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faXTwitter } from '@awesome.me/kit-65d95819ce/icons/classic/brands'

const options = {
  async setup() {
    const { currentUser, appliedHtmlcssTrial, paid } = storeToRefs(useUser())

    return {
      currentUser,
      appliedHtmlcssTrial,
      paid,
      faXTwitter,
    }
  },
  oldHead() {
    const scriptTags = scriptTagsByConfig({
      useCase: 'index',
      deviceSize: useNuxtApp().$deviceSize,
    })
    return {
      title: 'プログラミングスクール | 受講者数No.1のテックアカデミー',
      meta: [
        ...metaTags(
          {
            description:
              '【テックアカデミー(TechAcademy)】受講者数No.1のオンラインプログラミングスクール。通過率10%を突破した現役エンジニアからマンツーマンで学べる。転職や副業に活かせるスキルを最短4週間で習得。',
            ogUrl: '/',
          },
          process.env.host as string
        ),
      ],
      script: scriptTags!.script,
      noscript: scriptTags!.noscript,
    }
  },
  data() {
    return {
      entryDiscountTimeLimit: null,
      entrySettlementTimeLimit: null,
      entryPaymentUrl: null,
    }
  },
  computed: {
    BannerNameForShowing() {
      if (this.entrySettlementTimeLimit) {
        return 'settlement'
      } else if (this.entryDiscountTimeLimit) {
        return 'discount'
      } else {
        return null
      }
    },
  },
  async mounted() {
    const {
      data: {
        entryDiscountTimeLimit,
        entrySettlementTimeLimit,
        entryPaymentUrl,
      },
    } = await new IndexServices(this.$api).getTopTimeLimits()
    this.entryDiscountTimeLimit = entryDiscountTimeLimit
    this.entrySettlementTimeLimit = entrySettlementTimeLimit
    this.entryPaymentUrl = entryPaymentUrl

    await this.user.getCurrentUser()
  },
  methods: {},
  components: {
    // TeTopCoverConsultation,
    // TeCommonBannerMypage,
    TeCommonBannerLinksTitle,
    TeCommonReskillingBanner,
    TeCommonBannerSettlement,
    TeCommonBannerTimelimit,
    TeTopRecommend,
    TeTopBootcamp,
    TeCommonBannerDiagnoses,
    TeTopBannerLinks,
    TeTopWork,
    TeCommonAboutTechAcademy,
    TeCommonComparisonSchoolSidejob,
    TeTopReviewsTwitter,
    TeTopInterview,
    TeCommonBannerBriefingHtmlcssTrial,
    TeCommonButtonFixedBottom,
    FontAwesomeIcon,
  },
}

export default defineNuxtComponent(options)
</script>

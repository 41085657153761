<template>
  <section class="co-bg-striped">
    <div class="co-container-xs mx-auto grid grid-cols-1 gap-4 px-4 py-7 md:grid-cols-2">
      <a
        class="bot_open rounded bg-white text-co-gray-600 hover:text-co-gray-600"
        :href="$host() + '/counseling/htmlcss-schedule'"
      >
        <div class="flex items-center justify-between pb-4 pl-4 pr-3 pt-3 shadow-[0_0_8px_rgba(11,87,112,.2)]">
          <div class="flex">
            <img
              class="pr-4"
              src="~/assets/images/Te/TeCommon/Banner/Links/icon-5.svg"
              loading="lazy"
              alt="無料で相談する"
            >
            <div>
              <h3 class="co-display-11 mb-0 font-bold ">
                無料で<span class="leading-base text-co-primary-400">相談する</span>
              </h3>
              <span
                class="co-display-12 leading-normal"
              >まずはなんでも気軽に相談</span>
            </div>
          </div>
          <i
            class="fa fa-chevron-right text-co-gray-500"
            aria-hidden="true"
          />
        </div>
      </a>
      <a
        class="rounded bg-white text-co-gray-600 hover:text-co-gray-600"
        :href="$host() + '/trial/htmlcss-trial'"
      >
        <div class="flex items-center justify-between pb-4 pl-4 pr-3 pt-3 shadow-[0_0_8px_rgba(11,87,112,.2)]">
          <div class="flex">
            <img
              class="pr-4"
              src="~/assets/images/Te/TeCommon/Banner/Links/icon-1.svg"
              loading="lazy"
              alt="無料で体験する"
            >
            <div>
              <h3 class="co-display-11 mb-0 font-bold ">
                無料で<span class="leading-base text-co-primary-400">体験する</span>
              </h3>
              <span class="co-display-12 leading-normal">プログラミングを気軽に体験</span>
            </div>
          </div>
          <i
            class="fa fa-chevron-right text-co-gray-500"
            aria-hidden="true"
          />
        </div>
      </a>
      <a
        class="rounded bg-white text-co-gray-600 hover:text-co-gray-600"
        :href="$host() + '/briefing'"
      >
        <div class="flex items-center justify-between pb-4 pl-4 pr-3 pt-3 shadow-[0_0_8px_rgba(11,87,112,.2)]">
          <div class="flex">
            <img
              class="pr-4"
              src="~/assets/images/Te/TeCommon/Banner/Links/icon-2.svg"
              loading="lazy"
              alt="説明動画を見る"
            >
            <div>
              <h3 class="co-display-11 mb-0 font-bold ">
                説明動画を<span class="leading-base text-co-primary-400">見る</span>
              </h3>
              <span class="co-display-12 leading-normal">各コースの魅力を無料の動画で説明</span>
            </div>
          </div>
          <i
            class="fa fa-chevron-right text-co-gray-500"
            aria-hidden="true"
          />
        </div>
      </a>
      <a
        class="rounded bg-white text-co-gray-600 hover:text-co-gray-600"
        :href="$host() + '/course'"
      >
        <div class="flex items-center justify-between pb-4 pl-4 pr-3 pt-3 shadow-[0_0_8px_rgba(11,87,112,.2)]">
          <div class="flex">
            <img
              class="pr-4"
              src="~/assets/images/Te/TeCommon/Banner/Links/icon-3.svg"
              loading="lazy"
              alt="コースを探す"
            >
            <div>
              <h3 class="co-display-11 mb-0 font-bold ">
                コースを<span class="leading-base text-co-primary-400">探す</span>
              </h3>
              <span class="co-display-12 leading-normal">テックアカデミーのコースを紹介</span>
            </div>
          </div>
          <i
            class="fa fa-chevron-right text-co-gray-500"
            aria-hidden="true"
          />
        </div>
      </a>
    </div>
  </section>
</template>

<script lang="ts">
import TeCommonBoxShadowBlue from '~/components/Te/TeCommon/Box/TeCommonBoxShadowBlue/TeCommonBoxShadowBlue.vue'

const options = {
  components: {
    TeCommonBoxShadowBlue,
  },
}

export default defineNuxtComponent(options)
</script>

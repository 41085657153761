<template>
  <div class="relative rounded bg-co-gray-100">
    <div class="te-works-circle absolute">
      <div class="te-works-circle-number flex items-center justify-center rounded-full">
        <slot name="number">
          <span
            class="co-display-8 font-bold text-co-gray-50"
            :style="{ lineHeight: 0 }"
          >1</span>
        </slot>
      </div>
    </div>
    <div>
      <slot name="text">
        <p class="mb-0">
          テキスト<br>テキスト
        </p>
      </slot>
    </div>
  </div>
</template>

<style scoped>
.te-works-circle {
  top: -1.1rem;
  left: 50%;
  transform: translateX(-50%);
}

.te-works-circle-number {
  width: 2.25rem;
  height: 2.25rem;
  background-color: #5fa8b7;
}
</style>
